
import { Options, Vue } from "vue-class-component";
import { Project } from "@/assets/content/projects";
import HoverIcon from "@/components/HoverIcon.vue";

class Prop {
  project!: Project;
}

@Options({
  components: {
    HoverIcon,
  },
})
export default class ArchivedProjectCard extends Vue.with(Prop) {}
