
import { Options, Vue } from "vue-class-component";
import Footer from "@/components/layout/Footer.vue";
import FeaturedProjectCard from "./FeaturedProjectCard.vue";
import ArchivedProjectCard from "./ArchivedProjectCard.vue";

import { featuredProjects, archivedProjects } from "@/assets/content/projects";

@Options({
  components: {
    FeaturedProjectCard,
    ArchivedProjectCard,
    Footer,
  },
})
export default class Portfolio extends Vue {
  private featuredProjects = featuredProjects;
  private archivedProjects = archivedProjects;
}
