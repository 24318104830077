import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, TransitionGroup as _TransitionGroup, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, resolveComponent as _resolveComponent } from "vue"

const _hoisted_1 = { class: "featured-project-card" }
const _hoisted_2 = { class: "featured-project-card-img-wrapper" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "featured-project-card-heading" }
const _hoisted_5 = { class: "featured-project-card-links" }
const _hoisted_6 = ["href"]
const _hoisted_7 = ["innerHTML"]
const _hoisted_8 = { class: "featured-project-card-tags" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HoverIcon = _resolveComponent("HoverIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_TransitionGroup, {
        name: "slide",
        mode: "out-in"
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList([_ctx.currentImage], (index) => {
            return (_openBlock(), _createElementBlock("img", {
              class: "featured-project-card-img",
              key: index,
              src: _ctx.project.imageLinks[_ctx.currentImage]
            }, null, 8, _hoisted_3))
          }), 128))
        ]),
        _: 1
      })
    ]),
    _createElementVNode("div", null, [
      _createElementVNode("div", _hoisted_4, [
        _createElementVNode("h3", null, _toDisplayString(_ctx.project.title), 1),
        _createElementVNode("ul", _hoisted_5, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project.links, (link) => {
            return (_openBlock(), _createElementBlock("li", {
              key: link.to
            }, [
              _createElementVNode("a", {
                href: link.to,
                target: "_blank"
              }, [
                _createVNode(_component_HoverIcon, {
                  unhoveredIcon: link.unhoveredIcon,
                  hoveredIcon: link.hoveredIcon
                }, null, 8, ["unhoveredIcon", "hoveredIcon"])
              ], 8, _hoisted_6)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("p", {
        innerHTML: _ctx.project.description
      }, null, 8, _hoisted_7),
      _createElementVNode("ul", _hoisted_8, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project.tags, (tag) => {
          return (_openBlock(), _createElementBlock("li", { key: tag }, [
            _createElementVNode("code", null, _toDisplayString(tag), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}