import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "archived-project-card-wrapper" }
const _hoisted_2 = { class: "archived-project-card" }
const _hoisted_3 = { class: "archived-project-card-heading" }
const _hoisted_4 = { class: "archived-project-card-links" }
const _hoisted_5 = ["href"]
const _hoisted_6 = ["innerHTML"]
const _hoisted_7 = { class: "archived-project-card-tags" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_HoverIcon = _resolveComponent("HoverIcon")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", _hoisted_3, [
        _createElementVNode("h4", null, _toDisplayString(_ctx.project.title), 1),
        _createElementVNode("ul", _hoisted_4, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project.links, (link) => {
            return (_openBlock(), _createElementBlock("li", {
              key: link.to
            }, [
              _createElementVNode("a", {
                href: link.to,
                target: "_blank"
              }, [
                _createVNode(_component_HoverIcon, {
                  unhoveredIcon: link.unhoveredIcon,
                  hoveredIcon: link.hoveredIcon
                }, null, 8, ["unhoveredIcon", "hoveredIcon"])
              ], 8, _hoisted_5)
            ]))
          }), 128))
        ])
      ]),
      _createElementVNode("p", {
        class: "body2",
        innerHTML: _ctx.project.description
      }, null, 8, _hoisted_6),
      _createElementVNode("ul", _hoisted_7, [
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.project.tags, (tag) => {
          return (_openBlock(), _createElementBlock("li", { key: tag }, [
            _createElementVNode("code", null, _toDisplayString(tag), 1)
          ]))
        }), 128))
      ])
    ])
  ]))
}