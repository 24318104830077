
import { Options, Vue } from "vue-class-component";
import { Project } from "@/assets/content/projects";
import HoverIcon from "@/components/HoverIcon.vue";

class Prop {
  project!: Project;
}

@Options({
  components: {
    HoverIcon,
  },
})
export default class FeaturedProjectCard extends Vue.with(Prop) {
  private currentImage = 0;

  mounted(): void {
    setInterval(() => {
      if (this.currentImage < this.project.getImageLinkLength() - 1) {
        this.currentImage++;
      } else {
        this.currentImage = 0;
      }
    }, 5000);
  }
}
